import React from 'react'
import * as rawStyles from './styles.module.scss'
import { CardProps } from './types'
import ClickableArea from '../../basics/clickable-area/component'
import VideoBlock from '../video-block/component'

const Card: React.FC<CardProps> = ({
    title,
    tag,
    firstParagraph,
    secondParagraph,
    buttonText,
    imgSrc,
    altText,
    href,
    isLast,
    video,
}) => {
    const styles: any = rawStyles
    return (
        <div className={styles.c_card} data-is-last={isLast && isLast}>
            <div className={styles.card_content}>
                <div className={styles.text_content}>
                    {tag && <p className={styles.tag}>{tag}</p>}
                    <h2 className={styles.title}>{title}</h2>
                    {firstParagraph && <p>{firstParagraph}</p>}
                    {secondParagraph && <p>{secondParagraph}</p>}
                    {buttonText && (
                        <p className={styles.button}>
                            <ClickableArea variant='primary' color='orange' href={href}>
                                {buttonText}
                            </ClickableArea>
                        </p>
                    )}
                </div>
                <figure className={styles.figure}>
                    {imgSrc && altText ? (
                        <img src={imgSrc} alt={altText} />
                    ) : video ? (
                        <VideoBlock
                            className={styles.video}
                            src={video.src}
                            type={'video/mp4'}
                            typeOptimized={'video/webm'}
                            srcOptimized={video.srcOptimized}
                        />
                    ) : null}
                </figure>
            </div>
        </div>
    )
}

export default Card
